@import '../../styles/utils/var';

.small-wraps{
    margin: 0 auto;
    background-color: #ecf0f2;
    border: 1px solid #cad4d9;
    border-radius: 1rem;
    padding: 3rem;
    max-width:51.4rem;
    @media (max-width : map-get($grid , lg)) {
        padding: 2rem;
        max-width: 100%;
    }
}

.authid-save{
    margin: 3rem 0;
    display: flex;
    gap: 0 1rem;
    label{
        color: #2d2d2d;
        font-size: 1.9rem;
    }
    @media (max-width : map-get($grid , lg)) {
        margin: 1.7rem 0;
        label{
            font-size: 1.3rem;
        }
    }
}

.authid-save{
    margin-top: 3rem;
    display: flex;
    gap: 0 1rem;
    label{
        color: #2d2d2d;
        font-size: 1.9rem;
    }
    @media (max-width : map-get($grid , lg)) {
        margin-top: 1.7rem;
        label{
            font-size: 1.3rem;
        }
    }
}


.login-util {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 3rem;

    .button {
        margin-top: 3rem;
        @media (max-width: map-get($grid, lg)) {
            margin-top: 1.7rem;
        }
    }

    &.center {
        justify-content: center; // 버튼을 중앙 정렬
        gap: 1rem; // 버튼 사이 여백 추가
    }

    &.end {
        justify-content: end;
    }

    a {
        display: inline-flex;
        gap: 0 1rem;
        align-items: center;
        color: #242e42;
        font-size: 1.7rem;
        
        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    @media (max-width: map-get($grid, lg)) {
        margin-top: 2rem;

        a {
            font-size: 1.3rem;
        }
    }
}